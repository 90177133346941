body {
  margin: 0;
  padding: 0;
  font-family: "Consolas", sans-serif;
  font-size: 14px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Avenir", serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-default {
  background-color: #E91E63;
  border-color: #e7e7e7;
}

/* Title */
.navbar-default .navbar-brand {
  color: #fff;
}

/* Link */
.navbar-default .navbar-nav > li > a {
  color: #fff;
}

.navbar-default .navbar-brand:focus {
  color: #fff;
}

.icon-bar {
  background-color: #fff !important;
}

.navbar-toggle:focus, .navbar-toggle:hover {
  background-color: #504f54 !important;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 14px;
}
input[type=file] {
  width: 100%;
}

