.NewNote form {
    padding-bottom: 15px;
}

.NewNote form textarea {
    height: 300px;
    font-size: 24px;
}

.Home .notes h4 {
    font-family: "Consolas", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.Home .notes p {
    color: #666;
}

